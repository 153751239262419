import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DashboardTranslations from '../../DashboardTranslations.js';
import closeIcon from '../../images/close-icon.png'
import '../styles/successModalRestoreDraftJob.scss'


const SuccessModalRestoreDraftJob = (props) => (
  <div>
    <Dialog
      open={props.showSuccess}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="success-modal"
    >
      <DialogTitle className="dialog-title" id="alert-dialog-title"><img height="15" width="15" onClick={() => props.handleClose()} className="main-close-icon" src={closeIcon} alt="close-icon" /></DialogTitle>
      <div className="dialog-message-container">
        <div style={{ height: props.showCancel ? 120 : 175, padding: 15 }}>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: 14 }}>
            <div className="close-icon-center" >
              <div
                className='success-modal-header-text'
                style={props.headerTextStyle || {}}
              >
                {props.customTitle ? props.customTitle : DashboardTranslations.success_modal_header_text}
              </div>
            </div>

            <div style={{ ...{ whiteSpace: 'pre-line' }, ...(props.successMessageStyle || {}) }}>
              {props.successMessage ? props.successMessage : "ページをリフレッシュしてください。"}
            </div>
            {
              !props.showCancel &&
              <div className="ok-button" style={{ width: 150 }} onClick={() => props.handleCloseRedirect ? props.handleCloseRedirect() : props.handleClose()}>編集を続ける</div>
            }
            {props.showCancel &&
              <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", }}>
                <div className="ok-button" style={{ width: 150 }} onClick={() => props.handleCloseRedirect ? props.handleCloseRedirect() : props.handleClose(true)}>編集を続ける</div>

                <div
                  className="ok-button"
                  style={{ width: 150 }}
                  onClick={() => props.handleCloseRedirect ? props.handleCloseRedirect() : props.handleClose()}>
                  新規に作成する
                </div>
              </div>
            }
          </DialogContentText>
        </div>
      </div>
    </Dialog>
  </div>
);

export default SuccessModalRestoreDraftJob;
