import React, { Component } from 'react';
import { ENV_TYPE } from '../../constants';
import { connect } from 'react-redux';
import {
  addJobAPI,
  addPicturesAPI,
  editJobAPI,
  job,
  jobs,
  subscriptionAPI,
  checkIfLocationExists,
  getDraftJob,
  addDraftJobAPI,
} from '../helperFunctions/dataServices.js';
import AddJob from './AddJob.js';
import AddLocation from './AddLocation.js';
import AddInterviewLocation from './AddInterviewLocation.js';
import Checkbox from '@material-ui/core/Checkbox';
import DashboardTranslations from '../../DashboardTranslations.js';
import Dialog from '@material-ui/core/Dialog';
import Modal from '@material-ui/core/Modal';
import ErrorModal from '../components/ErrorModal';
import InfoModalBeforeFirstJobCreation from '../components/InfoModalBeforeFirstJobCreation';
import InfoModalAfterFirstJobCreation from '../components/InfoModalAfterFirstJobCreation';
import WorkImagesErrorModal from '../components/WorkImagesErrorModal';
import ReactGA from 'react-ga';
import HowToCreatePost from '../../images/how-to-create-post.png';
import InnerHeader from '../components/InnerHeader.js';
import SuccessModal from '../components/SuccessModal';
import SuccessModalRestoreDraftJob from "../components/SuccessModalRestoreDraftJob";
import PreviewJobs from './PreviewJobs';
import YouTube from 'react-youtube';
import ClipLoader from "react-spinners/ClipLoader";

import 'react-overlay-loader/styles.css';
import './styles/addJobsContainer.scss';
import '../JobsLanding/styles/jobsLanding.scss';
import InfoModalVerifyJobDetails from "../components/InfoModalVerifyJobDetails";
import questionMark from "../../images/svg/question-mark.svg";
import { getErrorMessage } from "../../messages/errors";
import InputBox from "../components/InputBox";


var stages = [
  { id: 1, name: "勤務地" },
  { id: 2, name: "面接場所" },
  { id: 3, name: "募集内容" },
  { id: 4, name: "確認" },
]

const opts = {
  height: '330',
  width: '600',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};

const optsResponsive = {
  height: '200',
  width: '375',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};


var adding = false
var sentPictures = true

class AddJobsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStage: 1,
      sameLocation: false,
      submittedWorkLocation: false,
      submittedInterviewLocation: false,
      submittedJobDetails: false,
      type: "view",
      collectDataWorkLocation: false,
      collectDataInterviewLocation: false,
      collectDataJobDetails: false,
      interviewPayload: {},
      jobPayload: {},
      nextButtonClickable: false,
      workPayload: {},
      draftJobDetails: {},
      isDraftView: false,
      draftDataCollection: 0,
      isSavingDraft: false,
      draftJobId: null,
      draftJobDetailsPayload: {},
      showDuplicateWorkLocationError: false,
      showDuplicateInterviewLocationError: false,
      picturesPayload: {},
      loading: false,
      showSuccess: false,
      showInitialInfo: true,
      showError: false,
      error: "",
      jobId: "",
      newJobId: "",
      jobDetails: {},
      edit: false,
      selectedPrefecture: '',
      showVideo: false,
      showImageModal: false,
      isAdmin: false,
      showDescriptionModal: false,
      subscriptionData: [],
      previewJobsInterviewLocationData: {},
      previewJobsData: {},
      imagesCount: -1,
      previewJobsWorkLocationData: {},
      firstTimeWorkImagesWarning: 0,
      workImagesWarning: false,
      showMainError: false,
      sentPictures: true,
      workImagesWarningMessage: "",
      showInfoModalBeforeFirstJobCreation: false,
      showInfoModalAfterFirstJobCreation: false,
      jobsLength: 0,
      verifyJobDetailsInfoModal: false,
      isOnlineIV: false,
      onlineIVTelephone: '',

      adJobData: {},
      jobInitializeResponse: {},
      collectJobDataCount: 0
    }
    this.myRef = React.createRef()
  }

  componentDidMount() {
    if (ENV_TYPE === "PROD") {
      this.initializeReactGA()
    }

    this.getJobs("initial")
    this.subscription()
    this.fetchDraftJobDetails();
    if (this.props.match.params.jobId) {
      this.setState({ jobId: this.props.match.params.jobId, edit: true }, () => this.fetchJobDetails())
      if (this.props.history.location.pathname.indexOf("copy-job") >= 0) {
        this.setState({ type: "copy" })
      }
    }
    else {
      var type = "add"
      if (this.props.history.location.pathname.indexOf("dashboard/edit") >= 0) {
        type = "edit"
      }
      else if (this.props.history.location.pathname.indexOf("dashboard/view") >= 0) {
        type = "view"
      }
      else if (this.props.history.location.pathname.indexOf("dashboard/copy") >= 0) {
        type = "copy"
      }
      else if (this.props.history.location.pathname.indexOf("dashboard/add") >= 0) {
        type = "add"
      }
      this.setState({ type: type })
    }
  }

  closeInfoModalVerifyJobDetails() {
    this.setState({ verifyJobDetailsInfoModal: !this.state.verifyJobDetailsInfoModal })
  }

  closeAddJobVideo() {
    this.setState({ showVideo: false })
    if (this.state.subscriptionData.subscription_status === "BT") {
      this.setState({ showDescriptionModal: false })
    }
  }

  getJobs(type) {
    if (type === 'initial') {
      this.setState({ loading: true })
    }
    jobs().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var length = response.data.length
        if (length === 0) {
          this.setState({ showInfoModalBeforeFirstJobCreation: true })
        }
        this.setState({ loading: false, jobsLength: length })
      }
      else {
        this.setState({ loading: false })
      }
    })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  subscription() {
    subscriptionAPI().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({ subscriptionData: response.data, isAdmin: response.data.is_admin })
        if (response.data.subscription_status === "BT" && this.state.showVideo === false) {
          this.setState({ showDescriptionModal: false })
        }
      }
      else {
        // this.setState({loading: false})
      }
    })
      .catch((e) => {
        this.setState({ loading: false })
        if (e.response.status === 403) {
          this.setState({ isAdmin: false })
        }
      })
  }

  initializeReactGA() {
    ReactGA.initialize('UA-104174852-1');
    ReactGA.pageview('/dashboard/add-jobs');
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  previewJobsLocation(payload, type) {
    if (type === "interview") {
      this.setState({ previewJobsInterviewLocationData: payload })
    }
    else if (type === "work") {
      this.setState({ previewJobsWorkLocationData: payload })
    }
    else {
      // if (this.state.type !== "view") {
      this.setState({ previewJobsData: payload })
      // }
    }
  }

  scrollToMyRef() {
    this.setState({ showMainError: true })
    // window.scrollTo(0, this.myRef.current.offsetTop)
  }

  setSelectedPrefecture(id) {
    this.setState({ selectedPrefecture: id })
  }

  handleChange = event => {
    if (event.target.name === 'onlineIVTelephone') {
      this.setState({
        [event.target.name]: event.target.value,
        draftJobDetailsPayload: {
          ...this.state.draftJobDetailsPayload,
          'step2': {
            ...this.state.draftJobDetailsPayload['step2'],
            'onlineIVTelephone': event.target.value,
          },
        },
      })
      return;
    }
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCheckbox = name => event => {
    const isChecked = event.target.checked;
    if (isChecked) {
      if (name === 'isOnlineIV') {
        this.setState({
          'isOnlineIV': true,
          'sameLocation': false,
          draftJobDetailsPayload: {
            ...this.state.draftJobDetailsPayload,
            'step2': {
              ...this.state.draftJobDetailsPayload['step2'],
              'isOnlineIV': true,
              'sameLocation': false,
            },
          },
        });
      } else {
        this.setState({
          previewJobsInterviewLocationData: this.state.previewJobsWorkLocationData,
          'isOnlineIV': false,
          'sameLocation': true,
          draftJobDetailsPayload: {
            ...this.state.draftJobDetailsPayload,
            'step2': {
              ...this.state.draftJobDetailsPayload['step2'],
              'isOnlineIV': false,
              'sameLocation': true,
            },
          },
        });
      }
    } else {
      if (name === 'isOnlineIV') {
        this.setState({
          'isOnlineIV': false,
          'onlineIVTelephone': '',
          draftJobDetailsPayload: {
            ...this.state.draftJobDetailsPayload,
            'step2': {
              ...this.state.draftJobDetailsPayload['step2'],
              'isOnlineIV': false,
              'onlineIVTelephone': '',
            },
          },
        });
      } else {
        this.setState({ [name]: false });
      }
    }
  };

  backArrowOnClick() {
    this.props.history.goBack();
  }

  fetchJobDetails() {
    this.setState({ loading: true })
    job(this.state.jobId).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        if (response.data.status !== "RN" && this.props.history.location.pathname.indexOf("copy") < 0) {
          this.setState({ type: "edit", showInitialInfo: true });
        } else if (response.data.status === "RN" && this.props.history.location.pathname.indexOf("dashboard/view") >= 0) {
          this.setState({ showInitialInfo: true });
        } else if(response.data.status === "RN") {
          this.setState({ type: "view", showInitialInfo: true });
        }
        this.setState({ jobDetails: response.data, loading: false, onlineIVTelephone: response.data.online_iv_telephone ? response.data.online_iv_telephone : '' }, () => this.setPreviewJobsValues())
      }
      else {
        this.setState({ loading: false })
      }
    })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  fetchDraftJobDetails() {
    this.setState({ loading: true })
    getDraftJob().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        if (response.data) {
          let draftJobId;
          if (response.data['id']) {
            draftJobId = response.data['id'];
            delete response.data['id'];
          }
          this.setState({
            draftJobId: draftJobId,
            draftJobDetails: response.data['data'],
            loading: false,
          })
          return;
        }
      }
      this.setState({ loading: false })
    })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  setPreviewJobsValues() {
    var { jobDetails } = this.state;

    let interviewLocationPayload = {}
    let isOnlineIV = false;
    if (jobDetails.interview_location) {
      interviewLocationPayload.brand_name_jp = jobDetails.interview_location.brand_name_jp
      interviewLocationPayload.brand_name_en = jobDetails.interview_location.brand_name_en
      interviewLocationPayload.address = jobDetails.interview_location.address
      interviewLocationPayload.nearest_station = jobDetails.interview_location.nearest_station.name
      interviewLocationPayload.minutes = jobDetails.minutes
    } else {
      isOnlineIV = true;
    }

    var workLocationPayload = {}
    workLocationPayload.brand_name_jp = jobDetails.location.brand_name_jp
    workLocationPayload.brand_name_en = jobDetails.location.brand_name_en
    workLocationPayload.address = jobDetails.location.address
    workLocationPayload.nearest_station = jobDetails.location.nearest_station.name
    workLocationPayload.minutes = jobDetails.location.minutes

    var {
    } = this.state;

    var jobPayload = {}
    var workShifts = jobDetails.work_timing
    var shifts = []
    var id = 0
    for (var key in workShifts) {
      var split = key.split('-')
      shifts.push({ id: id, timeFrom: split[0], timeTo: split[1], days: workShifts[key] })
      id += 1
    }

    var imageList = []

    if (jobDetails.work_images.length === 0) {
      if (jobDetails.default_image) {
        imageList[0] = jobDetails.default_image.url
      }
    }
    else {
      imageList = jobDetails.work_images.map(img => {
        return img.url;
      })
    }

    // jobPayload.preferred_other_certificates = jobDetails.preferred_other_certificates.join(",")
    jobPayload.sales_merits = jobDetails.sales_merits
    jobPayload.shifts = shifts
    jobPayload.hourly_salary_min = jobDetails.hourly_salary_min
    jobPayload.hourly_salary_max = jobDetails.hourly_salary_max
    jobPayload.training_hourly_salary_min = jobDetails.training_hourly_salary_min
    jobPayload.training_hourly_salary_max = jobDetails.training_hourly_salary_max
    jobPayload.training_days = jobDetails.training_days
    jobPayload.imageList = imageList

    this.setState({
      previewJobsData: jobPayload,
      previewJobsWorkLocationData: workLocationPayload,
      previewJobsInterviewLocationData: interviewLocationPayload,
      isOnlineIV: isOnlineIV
    })
  }

  updateAdJobData(jobData, initializeJobResponse) {
    this.setState({ adJobData: jobData, jobInitializeResponse: initializeJobResponse })
  }

  postJob() {
    this.verifyFullPayload()
    setTimeout(function () { this.setState({ collectDataWorkLocation: false, collectDataInterviewLocation: false, collectDataJobDetails: false }); }.bind(this), 2000);
  }


  addPictures(id, fromDraft=false) {
    if ((Object.keys(this.state.picturesPayload).length > 0 || this.state.picturesPayload instanceof FormData) && sentPictures) {
      sentPictures = false
      addPicturesAPI(this.state.picturesPayload, id, fromDraft).then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          if (fromDraft) {
            this.setState({ submitted: false, loading: false, isSavingDraft: false })
          } else {
            this.setState({ submitted: false, showSuccess: true, loading: false })
          }
          sentPictures = true
        }
        else {
          this.setState({ loading: false, isSavingDraft: false, error: response.response.data.error ? getErrorMessage('from_api_response', { 'apiErrorMessage': response.response.data.error }) : "" })
          sentPictures = true
        }
      })
        .catch((e) => {
          this.setState({ loading: false, showError: false, isSavingDraft: false })
          sentPictures = true
        })
    }
    else {
      if (fromDraft) {
        this.setState({ submitted: false, loading: false, isSavingDraft: false })
      } else {
        this.setState({ submitted: false, showSuccess: true, loading: false })
      }
    }
  }

  addJob(payload) {
    payload.online_iv_telephone = this.state.onlineIVTelephone ? this.state.onlineIVTelephone : null;
    if (adding === false) {
      adding = true
      addJobAPI(payload).then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          adding = false
          this.setState({ submitted: false, newJobId: response.data.id }, () => this.addPictures(response.data.id))
        }
        else {
          this.setState({ loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', { 'apiErrorMessage': response.response.data.error }) : "" })
        }
      })
        .catch((e) => {
          this.setState({ loading: false, showError: true })
        })
    }
  }

  editJob(payload) {
    payload.online_iv_telephone = this.state.onlineIVTelephone ? this.state.onlineIVTelephone : null;
    editJobAPI(payload, this.state.jobId).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({ loading: false, showSuccess: true })
      }
      else {
        this.setState({ loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', { 'apiErrorMessage': response.response.data.error }) : "" })
      }
    })
      .catch((e) => {
        this.setState({ loading: false, showError: true })
      })
  }

  verifyLocation(payload, locationType, sendFromDraft) {
    // console.log('Getting: ', payload, locationType, sendFromDraft);
    if (sendFromDraft) {
      if (locationType === "work") {
        this.setState({
          draftJobDetailsPayload: {
            ...this.state.draftJobDetailsPayload,
            'step1': { 'location': payload }
          }
        });
      } else {
        this.setState({
          draftJobDetailsPayload: {
            ...this.state.draftJobDetailsPayload,
            'step2': { 'location': payload }
          }
        })
      }
      return;
    }
    if (!payload.hasOwnProperty('locationId')) {
      if (locationType === 'interview' && payload.brand_name_jp === this.state.workPayload.brand_name_jp && payload.branch === this.state.workPayload.branch) {
        this.setState({ showDuplicateInterviewLocationError: true });
        return
      }
      checkIfLocationExists(payload.brand_name_jp, payload.branch)
        .then((response) => {
          if (response && response.status >= 200 && response.status < 300) {
            if (response.data.exists) {
              if (locationType === "work") {
                this.setState({ showDuplicateWorkLocationError: true });
              } else {
                this.setState({ showDuplicateInterviewLocationError: true });
              }
            } else {
              if (locationType === "work") {
                this.setState({
                  workPayload: payload,
                  draftJobDetailsPayload: {
                    ...this.state.draftJobDetailsPayload,
                    'step1': { 'location': payload }
                  },
                }, () => this.nextStage())
              } else {
                this.setState({
                  interviewPayload: payload,
                  draftJobDetailsPayload: {
                    ...this.state.draftJobDetailsPayload,
                    'step2': { 'location': payload }
                  }
                }, () => this.nextStage())
              }
            }
          }
        })
        .catch((e) => { })
    } else {
      if (locationType === "work") {
        this.setState({
          workPayload: payload,
          draftJobDetailsPayload: {
            ...this.state.draftJobDetailsPayload,
            'step1': { 'location': payload }
          },
        }, () => this.nextStage())
      }
      else {
        this.setState({
          interviewPayload: payload,
          draftJobDetailsPayload: {
            ...this.state.draftJobDetailsPayload,
            'step2': { 'location': payload }
          },
        }, () => this.nextStage())
      }
    }
  }

  verifyJob(payload, picturesPayload, imagesCount, sendFromDraft) {
    // console.log('Here:: ', payload, picturesPayload, imagesCount, sendFromDraft)
    if (sendFromDraft) {
      if (picturesPayload && imagesCount) {
        this.setState({
          picturesPayload: picturesPayload ? picturesPayload : {},
          imagesCount: imagesCount,
          draftJobDetailsPayload: {
            ...this.state.draftJobDetailsPayload,
            'step3': {
              ...this.state.draftJobDetailsPayload['step3'],
              ...payload,
            },
          },
        }, () => this.nextStage(true));
      } else {
        this.setState({
          draftJobDetailsPayload: {
            ...this.state.draftJobDetailsPayload,
            'step3': {
              ...this.state.draftJobDetailsPayload['step3'],
              ...payload,
            },
          },
        }, () => this.nextStage(true))
      }
    } else {
      this.setState({
        jobPayload: payload,
        picturesPayload: picturesPayload ? picturesPayload : {},
        imagesCount: imagesCount,
        draftJobDetailsPayload: {
          ...this.state.draftJobDetailsPayload,
          'step3': {
            ...this.state.draftJobDetailsPayload['step3'],
            ...payload,
          },
        },
      }, () => this.nextStage())
    }
  }

  setNextButtonClickable(nextButtonClickable) {
    this.setState({ nextButtonClickable: nextButtonClickable });
  }

  nextStage(fromSaveDraft = false) {
    var { currentStage, workPayload, interviewPayload, jobPayload, type } = this.state
    // console.log('Going to Call the API :: ', fromSaveDraft, currentStage)
    if (currentStage === 1) {
      this.setState({ submittedWorkLocation: true, collectDataWorkLocation: true })
      if (fromSaveDraft) {
        this.setState({ submittedWorkLocation: false, collectDataWorkLocation: false }, () => this.saveDraftJobPayload());
        return;
      }
      if (Object.keys(workPayload).length > 0) {
        this.setState({ currentStage: 2 })
      }
      else {
        setTimeout(function () { this.setState({ submittedWorkLocation: true, collectDataWorkLocation: false }); }.bind(this), 2000);
      }
    }
    else if (currentStage === 2) {
      this.setState({ submittedInterviewLocation: true, collectDataInterviewLocation: true })
      if (!('step2' in this.state.draftJobDetailsPayload) && (this.state.sameLocation || this.state.isOnlineIV)) {
        if (this.state.sameLocation) {
          this.setState({
            draftJobDetailsPayload: {
              ...this.state.draftJobDetailsPayload,
              'step2': {
                ...this.state.draftJobDetailsPayload['step2'],
                'isOnlineIV': false,
                // onlineIVTelephone: '',
                'sameLocation': true,
              },
            },
          });
        } else if (this.state.isOnlineIV) {
          this.setState({
            draftJobDetailsPayload: {
              ...this.state.draftJobDetailsPayload,
              'step2': {
                ...this.state.draftJobDetailsPayload['step2'],
                'isOnlineIV': true,
                onlineIVTelephone: this.state.onlineIVTelephone,
                'sameLocation': false,
              },
            },
          });
        }
      }
      if (fromSaveDraft) {
        this.setState({ submittedInterviewLocation: false, collectDataInterviewLocation: false }, () => this.saveDraftJobPayload());
        return;
      }
      if (this.state.isOnlineIV) {
        if (this.state.type === 'view' || this.state.onlineIVTelephone.length === 10 || this.state.onlineIVTelephone.length === 11) {
          this.setState({ currentStage: 3 })
        } else {
          setTimeout(function () { this.setState({ submittedInterviewLocation: true, collectDataInterviewLocation: false }); }.bind(this), 2000);
        }
      } else {
        if (Object.keys(interviewPayload).length > 0 || this.state.sameLocation || this.state.isOnlineIV) {
          this.setState({ currentStage: 3 })
        } else {
          setTimeout(function () { this.setState({ submittedInterviewLocation: true, collectDataInterviewLocation: false }); }.bind(this), 2000);
        }
      }
    }
    else if (currentStage === 3) {
      if (fromSaveDraft) {
        this.saveDraftJobPayload();
        return;
      }
      // console.log('SER:: ', fromSaveDraft);
      this.setState({
        submittedJobDetails: true,
        collectDataJobDetails: true,
        collectJobDataCount: (this.state.collectJobDataCount + 1)
      });
      if (Object.keys(jobPayload).length > 0 && this.state.nextButtonClickable) {
        if (type === 'view') {
          this.setState({ currentStage: 4 });
        } else {
          this.setState({ currentStage: 4, verifyJobDetailsInfoModal: true });
        }
      }
      else {
        setTimeout(function () { this.setState({ submittedJobDetails: true, collectDataJobDetails: false }); }.bind(this), 2000);
      }
    }

    this.maintainTextAreaHeight('job-detail')
    this.maintainTextAreaHeight('job-detail-text')
  }

  maintainTextAreaHeight(elementId) {
    setTimeout(function () {
      const jobDetailSection = document.getElementById(elementId)
      if (jobDetailSection && jobDetailSection.scrollHeight > 0) {
        jobDetailSection.style.height = "5px";
        jobDetailSection.style.height = (jobDetailSection.scrollHeight) + "px";
      }
    }, 500)
  }

  verifyFullPayload() {
    var { workPayload, interviewPayload, jobPayload, sameLocation, edit, loading, firstTimeWorkImagesWarning, imagesCount, type, isOnlineIV } = this.state
    if (Object.keys(workPayload).length > 0 && Object.keys(jobPayload).length > 0) {
      let newPayload = {}
      if (sameLocation) {
        if (firstTimeWorkImagesWarning !== 0) {
          this.setState({ loading: true })
        }

        if (workPayload.hasOwnProperty('locationId')) {
          newPayload.interview_location_id = parseInt(workPayload.locationId);
          newPayload.location_id = parseInt(workPayload.locationId);
        } else {
          newPayload.interview_location = workPayload;
          newPayload.location = workPayload;
        }
        newPayload = { ...newPayload, ...jobPayload }
        if (edit) {
          this.setState({ loading: true })
          if (type === "copy") {
            this.addJob(newPayload)
          }
          else {
            this.addPictures(this.state.jobId)
            this.editJob(newPayload)
          }
        }
        else if (loading === false) {
          if (firstTimeWorkImagesWarning === 0 && imagesCount === 0) {
            this.setState({ workImagesWarning: true, workImagesWarningMessage: getErrorMessage('add_job_work_images_not_added_error'), firstTimeWorkImagesWarning: 1 })
          }
          else {
            this.setState({ loading: true })
            this.addJob(newPayload)
          }
        }
      }
      else if (isOnlineIV) {
        if (firstTimeWorkImagesWarning !== 0) {
          this.setState({ loading: true })
        }

        newPayload.interview_location = null;

        if (workPayload.hasOwnProperty('locationId')) {
          newPayload.location_id = parseInt(workPayload.locationId);
        } else {
          newPayload.location = workPayload;
        }
        newPayload = { ...newPayload, ...jobPayload }
        if (edit) {
          this.setState({ loading: true })
          if (type === "copy") {
            this.addJob(newPayload)
          }
          else {
            this.addPictures(this.state.jobId)
            this.editJob(newPayload)
          }
        }
        else if (loading === false) {
          if (firstTimeWorkImagesWarning === 0 && imagesCount === 0) {
            this.setState({ workImagesWarning: true, workImagesWarningMessage: getErrorMessage('add_job_work_images_not_added_error'), firstTimeWorkImagesWarning: 1 })
          }
          else {
            this.setState({ loading: true })
            this.addJob(newPayload)
          }
        }
      }
      else if (Object.keys(interviewPayload).length > 0) {
        if (firstTimeWorkImagesWarning !== 0) {
          this.setState({ loading: true })
        }

        if (interviewPayload.hasOwnProperty('locationId')) {
          newPayload.interview_location_id = parseInt(interviewPayload.locationId);
        } else {
          newPayload.interview_location = interviewPayload;
        }

        if (workPayload.hasOwnProperty('locationId')) {
          newPayload.location_id = parseInt(workPayload.locationId);
        } else {
          newPayload.location = workPayload;
        }

        newPayload = { ...newPayload, ...jobPayload }
        if (edit) {
          this.setState({ loading: true })
          if (type === "copy") {
            this.addJob(newPayload)
          }
          else {
            this.addPictures(this.state.jobId)
            this.editJob(newPayload)
          }
        }
        else if (loading === false) {
          if (firstTimeWorkImagesWarning === 0 && imagesCount === 0) {
            this.setState({ workImagesWarning: true, workImagesWarningMessage: getErrorMessage('add_job_work_images_not_added_error'), firstTimeWorkImagesWarning: 1 })
          }
          else {
            this.setState({ loading: true })
            this.addJob(newPayload)
          }
        }
      }
      else {
        this.scrollToMyRef()
      }
    }
    else {
      this.scrollToMyRef()
    }
  }

  saveDraftJobPayload() {
    var { draftJobDetailsPayload, currentStage } = this.state
    // console.log('API Here:: ', draftJobDetailsPayload, currentStage);
    if (Object.keys(draftJobDetailsPayload).length === 0) {
      return;
    }
    if (this.state.isSavingDraft) {
      return;
    }
    // console.log('Here:: 00:: ', this.state.isSavingDraft);
    this.setState({ isSavingDraft: true }, () => {
      // console.log('Here:: 01:: ', currentStage < 3);
      if (currentStage < 3) {
        addDraftJobAPI(draftJobDetailsPayload).then((res) => {
          this.setState({isSavingDraft: false});
        }).catch((err) => {
          this.setState({isSavingDraft: false});
        });
      } else {
        // console.log('Here:: 11:: ', 'step3' in draftJobDetailsPayload, draftJobDetailsPayload);
        if ('step3' in draftJobDetailsPayload) {
          addDraftJobAPI(draftJobDetailsPayload).then((response) => {
            // console.log(' ----- >>>>>> Done ', response, response.data && response.data.id);
            if (response.data && response.data.id) {
              this.addPictures(response.data.id, true);
            } else {
              this.setState({isSavingDraft: false});
            }
          }).catch((err) => {
            this.setState({isSavingDraft: false});
          });
        }
      }
    })
  }

  handleShowDescription() {
    this.setState({ showDescriptionModal: false })
  }

  handleClose() {
    this.setState({ showError: false })
  }

  handleSuccess() {
    if (this.state.jobsLength === 0) {
      this.setState({ showInfoModalAfterFirstJobCreation: true, showSuccess: false })
    }
    else {
      if (this.state.newJobId === "") {
        this.props.history.push('/dashboard')
      }
      else {
        this.props.history.push('/dashboard/add-interview-times/' + this.state.newJobId + '?new-job')
      }
    }
  }

  handleInitialInfoSuccess() {
    this.setState({ showInitialInfo: false })
  }

  handleSaveDraftModelSuccess(showFromDraft) {
    if (showFromDraft === true) {
      const draftJobDetails = this.state.draftJobDetails;
      if ('step2' in draftJobDetails) {
        if ('isOnlineIV' in draftJobDetails['step2'] && draftJobDetails['step2']['isOnlineIV']) {
          this.setState({
            isDraftView: true,
            draftDataCollection: this.state.draftDataCollection + 1,
            'isOnlineIV': draftJobDetails['step2']['isOnlineIV'],
            'onlineIVTelephone': draftJobDetails['step2']['onlineIVTelephone'] ? draftJobDetails['step2']['onlineIVTelephone'] : '',
          })
        }
        if ('sameLocation' in draftJobDetails['step2'] && draftJobDetails['step2']['sameLocation']) {
          this.setState({
            isDraftView: true,
            draftDataCollection: this.state.draftDataCollection + 1,
            'onlineIVTelephone': '',
            'sameLocation': draftJobDetails['step2']['sameLocation'],
          })
        }
        if ('location' in draftJobDetails['step2']) {
          this.setState({
            isDraftView: true,
            draftDataCollection: this.state.draftDataCollection + 1,
          })
        }
      } else {
        this.setState({
          isDraftView: true,
          draftDataCollection: this.state.draftDataCollection + 1,
        });
      }
    } else {
      this.setState({
        draftDataCollection: this.state.draftDataCollection + 1,
      });
    }
  }

  handleErrorClose = () => {
    this.setState({ showError: false, error: '', email: "", workImagesWarning: false })
  }

  previousStage() {
    var { currentStage, locationStage, locationStageIV, jobStage } = this.state
    if (currentStage === 1) {
      if (locationStage > 1 && locationStage <= 3) {
        this.setState({ locationStage: this.state.locationStage - 1 })
      }
      else {

      }
    }
    else if (currentStage === 2) {
      this.setState({
        currentStage: 1,
        showDuplicateWorkLocationError: false
      })
    }
    else if (currentStage === 3) {
      this.setState({ currentStage: 2, showDuplicateInterviewLocationError: false })
    }
    else if (currentStage === 4) {
      this.setState({ currentStage: 3 })
    }
  }

  redirectToStage(stage) {
    this.setState({ currentStage: stage })
  }

  closeInfoModalBeforeFirstJobCreation() {
    this.setState({ showInfoModalBeforeFirstJobCreation: false })
  }

  closeInfoModalAfterFirstJobCreation() {
    this.setState({ showInfoModalAfterFirstJobCreation: false })
    if (this.state.newJobId === "") {
      this.props.history.push('/dashboard')
    }
    else {
      this.props.history.push('/dashboard/add-interview-times/' + this.state.newJobId + '?new-job')
    }

  }


  render() {
    const { sameLocation, submittedWorkLocation, submittedInterviewLocation, submittedJobDetails, showError, type,
      subscriptionData, showSuccess, edit, jobDetails, selectedPrefecture, error, showDescriptionModal, previewJobsData,
      previewJobsWorkLocationData, previewJobsInterviewLocationData, workImagesWarning, workImagesWarningMessage,
      verifyJobDetailsInfoModal, draftJobId, currentStage, showInfoModalBeforeFirstJobCreation, showInfoModalAfterFirstJobCreation,
      showDuplicateWorkLocationError, draftJobDetails, draftDataCollection, isDraftView, showDuplicateInterviewLocationError, showInitialInfo, isOnlineIV,
      onlineIVTelephone } = this.state;

    let viewJobButton = DashboardTranslations.view_job_button_text.split('\n').map((item, i) => <div key={i}>{item}</div>);
    const stageList = stages.map(stage => {
      return (
        <div id={stage.id} className="single-stage">
          <div
            style={{
              backgroundColor: stage.id === currentStage ? "#263242" : "#FFFFFF",
              color: stage.id === currentStage ? "#FFFFFF" : "#263242"
            }}
            className="stage-id">{stage.id}</div>
          <div
            style={{
              color: stage.id === currentStage ? "#333333" : "#858585"
            }}
            className="stage-name">{stage.name}</div>
        </div>
      )
    });

    let jobType;

    const jobTypeUiOption = {
      PT1: {
        color: '#FC6B6D',
        text: 'アルバイト採用限定プラン'
      },
      PT2: {
        color: '#28C1DD',
        text: 'オールインワンプラン・アルバイト採用'
      },
      FT: {
        color: '#1A9EFF',
        text: 'オールインワンプラン・正社員採用'
      },
      SSV: {
        color: '#7B70F7',
        text: 'オールインワンプラン・特定技能採用'
      },
    }

    if (this.props.location.state && this.props.location.state.job_type) {
      jobType = this.props.location.state.job_type
    }
    if (!jobType && type === 'add') {
      window.location.href = '/dashboard/select-job-type'
    }

    if (this.state.jobDetails.job_type) {
      jobType = this.state.jobDetails.job_type
    }

    return (
      <>
        <div className="jobs-landing-container">
          <InnerHeader
            primary={false}
            headerText=""
            backArrowOnClick={this.backArrowOnClick.bind(this)}
          />
        </div>

        <div className="add-jobs-main-container">
          <div className="add-job-outer-container">
            <div className="add-job-inner-container">
              <div
                className='company-details-header-container'
                style={{
                  backgroundColor: jobTypeUiOption[jobType] ? jobTypeUiOption[jobType].color : 'rgb(38, 50, 66)'
                }}
              >
                <div className='header'>
                  {/*{(type === "add" || type === "copy") ? "求人記事の編集" : "求人記事の編集"}*/}
                  <div>
                    <div>
                      求人記事の作成
                    </div>
                    <div style={{
                      fontSize: '20px'
                    }}>
                      {jobTypeUiOption[jobType] ? jobTypeUiOption[jobType].text : ''}
                    </div>
                  </div>

                  {(currentStage === 4 && type !== 'view') && <img src={questionMark} alt="ss" style={{ height: 40, cursor: 'pointer', paddingTop: 3 }} onClick={() => this.closeInfoModalVerifyJobDetails()} />}

                </div>
              </div>
              {currentStage !== 5 && <div className="stage-container">
                {stageList}
              </div>}
              <div className="single-stage">
                <AddLocation
                  type={this.state.type}
                  currentStage={currentStage}
                  showDuplicateWorkLocationError={showDuplicateWorkLocationError}
                  previewJobsLocation={this.previewJobsLocation.bind(this)}
                  submitted={submittedWorkLocation}
                  setSelectedPrefecture={this.setSelectedPrefecture.bind(this)}
                  edit={edit}
                  jobDetails={jobDetails}
                  isDraftView={isDraftView}
                  draftJobDetails={draftJobDetails}
                  draftDataCollection={draftDataCollection}
                  collectData={this.state.collectDataWorkLocation}
                  locationType="work"
                  verifyLocation={this.verifyLocation.bind(this)}
                  scrollToMyRef={this.scrollToMyRef.bind(this)}
                />
              </div>
              <div className="single-stage">
                {type === "add" && currentStage === 2 && <div className="checkbox-container" style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    style={{ marginBottom: 0 }}
                    onChange={this.handleCheckbox('sameLocation')}
                    value={sameLocation.toString()}
                    checked={sameLocation}
                    color="primary"
                  />
                  {DashboardTranslations.add_job_page_location_same_description}
                </div>}

                {currentStage === 2 && <>
                  <div className="checkbox-container" style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      style={{ marginBottom: 0 }}
                      onChange={this.handleCheckbox('isOnlineIV')}
                      value={isOnlineIV.toString()}
                      checked={isOnlineIV}
                      color="primary"
                      disabled={type === 'edit' || type === 'view'}
                    />
                    <span style={{ opacity: (type === 'edit' || type === 'view') ? 0.4 : 1 }}>
                      面接はオンラインで実施する
                    </span>
                  </div>
                  {/*<span style={{display: "flex", marginLeft: 42, color: '#9B9B9B', fontSize: 10}}>*/}
                  {/*  オンライン面接を選択した場合は電話番号以外の入力はできません。*/}
                  {/*</span>*/}
                  {/*<span style={{display: "flex", marginLeft: 42, color: '#9B9B9B', fontSize: 10}}>*/}
                  {/*  求人記事にはオンライン面接である旨が表示されます。*/}
                  {/*</span>*/}

                  {isOnlineIV &&
                    <InputBox
                      errorMessage={DashboardTranslations.add_location_phone_number_error_message}
                      error={type !== 'view' && submittedInterviewLocation && (onlineIVTelephone.length !== 10 && onlineIVTelephone.length !== 11)}
                      label='電話番号（半角・ハイフン無し）'
                      name="onlineIVTelephone"
                      disabled={type === 'view'}
                      explanatoryText='面接に関する問合せ先の番号を入力してください。'
                      value={onlineIVTelephone}
                      handleChange={this.handleChange.bind(this)}
                    />
                  }
                </>
                }

                {!sameLocation && !isOnlineIV && (jobDetails.interview_location || type === "add" || type === "copy") &&
                  <>
                    <br />
                    <AddInterviewLocation
                      previewJobsLocation={this.previewJobsLocation.bind(this)}
                      submitted={submittedInterviewLocation}
                      edit={edit}
                      currentStage={currentStage}
                      setSelectedPrefecture={this.setSelectedPrefecture.bind(this)}
                      jobDetails={jobDetails}
                      collectData={this.state.collectDataInterviewLocation}
                      type={this.state.type}
                      locationType="interview"
                      isDraftView={isDraftView}
                      draftJobDetails={draftJobDetails}
                      draftDataCollection={draftDataCollection}
                      verifyLocation={this.verifyLocation.bind(this)}
                      scrollToMyRef={this.scrollToMyRef.bind(this)}
                      showDuplicateInterviewLocationError={showDuplicateInterviewLocationError}
                    />
                  </>
                }
              </div>

              {
                <div className="single-stage">
                  <AddJob previewJobsLocation={this.previewJobsLocation.bind(this)} history={this.props.history} submitted={submittedJobDetails}
                    selectedPrefecture={selectedPrefecture} edit={edit} collectData={this.state.collectDataJobDetails} jobDetails={jobDetails}
                    collectDataCount={this.state.collectJobDataCount}
                    verifyJob={this.verifyJob.bind(this)}
                    setNextButtonClickable={this.setNextButtonClickable.bind(this)}
                    type={this.state.type}
                    isDraftView={isDraftView}
                    draftJobDetails={draftJobDetails}
                    draftDataCollection={draftDataCollection}
                    currentStage={currentStage}
                    draftJobId={draftJobId}
                    scrollToMyRef={this.scrollToMyRef.bind(this)}
                    maintainHeight={this.maintainTextAreaHeight.bind(this)}
                    updateAdJobData={this.updateAdJobData.bind(this)}
                  />
                </div>
              }

              {currentStage === 4 &&
                <div className="single-stage">
                  <PreviewJobs
                    redirectToStage={this.redirectToStage.bind(this)}
                    jobsData={previewJobsData}
                    interviewLocationData={previewJobsInterviewLocationData}
                    workLocationData={previewJobsWorkLocationData}
                    onlineIVTelephone={onlineIVTelephone}
                    adJobData={this.state.adJobData}
                    jobType={jobType}
                    type={this.state.type}
                    isSameLocation={sameLocation}
                    maintainHeight={this.maintainTextAreaHeight.bind(this)}
                  />
                </div>
              }

              <ErrorModal error={error} handleClose={this.handleClose.bind(this)} showError={this.state.showError} />
              <div className="details-page-button-container">
                {currentStage !== 1 && <div className="previous-button" onClick={() => this.previousStage()} style={{ textDecorationLine: 'underline' }}>
                  戻る
                </div>}
                {currentStage === 1 && <div className="dummy-button">
                </div>}
                {(type === "add" && currentStage <= 3) && <button className="next-button" style={{ margin: "0px 5px", }} onClick={() => this.setState({ draftDataCollection: this.state.draftDataCollection + 1 }, () => {
                  if (currentStage < 3) {
                    this.nextStage(true);
                  }
                })}>
                  {
                    this.state.isSavingDraft ?
                    <ClipLoader
                      loading={this.state.isSavingDraft}
                      color={'#ffffff'}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    /> : <span style={{wordBreak: "keep-all", padding: "0px 8px", }}>記事を保存する</span>
                  }
                </button>}
                {currentStage !== 4 && <button className="next-button" onClick={() => this.nextStage()}>
                  次へ
                </button>}
                {
                  (type === "add" || type === "edit" || type === "copy")
                  && currentStage === 4
                  && <button
                    className={type === "view" ? "view next-button" : "next-button"}
                    onClick={() => type !== "view" && this.postJob()}
                    disabled={type === "view"}>
                    {type === "view" ? viewJobButton : DashboardTranslations.post_job_button_text}
                  </button>
                }

              </div>
              {currentStage === 9 && <button className="company-details-close-button" onClick={() => this.closeModal()}>
                記事を作成する
              </button>}
            </div>
          </div>
          <SuccessModal successMessage={subscriptionData.subscription_status === "BT" ? DashboardTranslations.add_job_page_create_job_success_message : DashboardTranslations.add_job_success_message} handleClose={this.handleSuccess.bind(this)} showSuccess={showSuccess} />
          {
            (type === "add") &&
            <SuccessModal
              successMessage={'作成途中の記事は１件までしか保存できません。ご了承ください。'}
              handleClose={() => {
                this.setState({
                  draftDataCollection: this.state.draftDataCollection + 1,
                })
              }}
              showSuccess={((Object.keys(draftJobDetails).length > 0 && this.state.draftDataCollection === 2) || (Object.keys(draftJobDetails).length === 0 && this.state.draftDataCollection === 1))  && !isDraftView}
            />
          }

          {/* {
            type !== 'view' &&
            <SuccessModal
              successMessage={type === 'edit' ? DashboardTranslations.edit_job_pop_up_info : DashboardTranslations.save_job_popup_info}
              handleClose={this.handleInitialInfoSuccess.bind(this)}
              showSuccess={this.state.showInitialInfo}
              customTitle={type === 'edit' ? 'ご注意ください' : '作成途中の保存はできません'}
              headerTextStyle={{ fontSize: '26px', paddingBottom: '20px' }}
              successMessageStyle={{ fontSize: '13px' }}
            />
          } */}

          {
            (type === "add") &&
              <SuccessModalRestoreDraftJob
              successMessage={'作成中の記事の編集を続けますか？'}
              handleClose={this.handleSaveDraftModelSuccess.bind(this)}
              showSuccess={Object.keys(draftJobDetails).length > 0 && !isDraftView && this.state.draftDataCollection === 0}
              customTitle={'作成中の記事が１件あります。'}
              showCancel={true}
              headerTextStyle={{ fontSize: '26px', paddingBottom: '20px' }}
              successMessageStyle={{ fontSize: '13px' }}
            />
          }

          <SuccessModal successMessage={DashboardTranslations.add_job_page_main_description_modal_text} handleClose={this.handleShowDescription.bind(this)} showSuccess={showDescriptionModal} />
          <ErrorModal error={error} handleClose={this.handleErrorClose.bind(this)} showError={showError} />
          <WorkImagesErrorModal error={workImagesWarningMessage} handleClose={this.handleErrorClose.bind(this)} showError={workImagesWarning} />
          <Dialog
            open={this.state.showVideo}
            onClose={() => this.closeAddJobVideo()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="video-modal-container"
            style={{}}
          >
            <div className="video-desktop">
              <div className="close-button-container">
                <span className="close-video-button" onClick={() => this.closeAddJobVideo()}>x</span>
              </div>
              <YouTube
                videoId="esN942bxaVo"
                opts={opts}
                onReady={this._onReady}
              />
            </div>
            <div className="video-mobile">
              <YouTube
                videoId="esN942bxaVo"
                opts={optsResponsive}
                onReady={this._onReady}
              />
            </div>
          </Dialog>

          <Modal
            open={this.state.showImageModal}
            onClose={() => this.setState({ showImageModal: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="image-modal-container"
            style={{ minWidth: 800 }}
          >
            <div className="image-modal-inner">
              <div className="close-button-container">
                <span className="close-video-button" onClick={() => this.setState({ showImageModal: false })}>x</span>
              </div>
              <img src={HowToCreatePost} alt="create-post" className="create-post-modal-image" />
            </div>
          </Modal>
          {showInfoModalBeforeFirstJobCreation && <InfoModalBeforeFirstJobCreation closeInfoModalBeforeFirstJobCreation={this.closeInfoModalBeforeFirstJobCreation.bind(this)} />}
          {showInfoModalAfterFirstJobCreation && <InfoModalAfterFirstJobCreation closeInfoModalAfterFirstJobCreation={this.closeInfoModalAfterFirstJobCreation.bind(this)} />}
          {verifyJobDetailsInfoModal && <InfoModalVerifyJobDetails closeInfoModalVerifyJobDetails={this.closeInfoModalVerifyJobDetails.bind(this)} />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.language,
    token: state.user.token
  }
};

AddJobsContainer = connect(mapStateToProps)(AddJobsContainer);

export default AddJobsContainer;
