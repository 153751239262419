import React, { Component } from 'react';
import down from "../../images/svg/down-new.svg";
import down_disabled from "../../images/svg/down-new-disabled.svg";

class MultipleSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {}

  handleChange = event => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    var { name, label, error, errorMessage, disabled, items, selectedItems, expand, mandatoryText
    } = this.props
    return (
      <div className="input-box-container">
        <div className="label-container">{label}{mandatoryText}</div>
        {
          error &&
          <div className="error-message-container">
          {errorMessage}
          </div>
        }
        <div className="drop-list" style={{
            opacity: disabled ? 0.7 : 1,
          }}>
          <div
            style={{
              border: `1px solid ${error ? '#e06e6f' : '#9B9B9B'}`,
              borderRadius: '5px',
              padding: '0px 5px 0px 10px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '40px',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={(e) => this.props.expandCollapse(e)}
            >
              <div style={{opacity: disabled ? 0.7 : 1, fontSize: '14px'}}>
                {Boolean(selectedItems.length) ? '選択中': '選択'}
              </div>
              <div>
                {disabled ? 
                  <img src={down_disabled} alt='down-arrow' style={{height: '7px', marginTop: '7px'}}/>
                :
                  <img src={down} alt='down-arrow' style={{height: '7px', marginTop: '7px'}}/>
                }
              </div>
            </div>

            {
              (expand || disabled || Boolean(selectedItems.length)) &&
              <div>
                <div style={{textAlign: 'left'}}>
                  {
                    items &&
                    items.length &&
                    items
                      .filter(item => selectedItems.includes(item.id))
                      .map(item => <div key={`${label}-${item.name}`}>{item.name}</div>)
                  }
                </div>
                {
                  !disabled &&
                  <div
                    className='clickable-link'
                    onClick={(e) => this.props.openMultipleSelectionModal(e)}
                  >
                    編集する
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default MultipleSelect;
