import React from "react";
import Toolbar from "react-big-calendar/lib/Toolbar";
import "./styles/customToolbar.scss";

export default class CustomToolbar extends Toolbar {
  componentDidMount() {
    const view = this.props.view;
  }

  render() {
    function convertLabelToJPFormat(label) {
      const daysJP = ["日", "月", "火", "水", "木", "金", "土"];

      const mobileDatePattern = /(\w+)\s(\w+)\s(\d+)/;
      const desktopDatePattern = /(\w+)\s(\d+)\s-\s(\d+)/;

      if (mobileDatePattern.test(label)) {
        const match = label.match(mobileDatePattern);
        if (match) {
          const [, day, month, date] = match;
          const parsedDate = new Date(
            `${month} ${date}, ${new Date().getFullYear()}`
          );
          const monthDigitJP = parsedDate.getMonth() + 1;
          const dayJP = daysJP[parsedDate.getDay()];

          return `${monthDigitJP}月 ${parsedDate.getDate()}日（${dayJP}）`;
        }
      } else if (desktopDatePattern.test(label)) {
        const match = label.match(desktopDatePattern);
        if (match) {
          const [, month, startDay, endDay] = match;
          const parsedDate = new Date(
            `${month} ${startDay}, ${new Date().getFullYear()}`
          );
          const monthDigitJP = parsedDate.getMonth() + 1;

          return `${monthDigitJP}月 ${startDay}日 - ${endDay}日`;
        }
      }

      return label;
    }

    return (
      <div>
        <div className="custom-toolbar-container">
          <div
            className="arrow-left"
            onClick={() => this.navigate("PREV")}
          ></div>
          <div className="rbc-toolbar-label">
            {convertLabelToJPFormat(this.props.label)}
          </div>
          <div
            className="arrow-right"
            onClick={() => this.navigate("NEXT")}
          ></div>
        </div>
      </div>
    );
  }
}
